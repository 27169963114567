export const initMobileMenu = () => {
    // VARS
    const toggle = document.querySelector('[data-toggle-menu]');
    const overlay = document.querySelector('[data-overlay]');

    // LISTENERS
    toggle.addEventListener('click', onClickToggleHandler, false);

    // FUNCTIONS
    function onClickToggleHandler() {
        toggle.classList.toggle('active');
        overlay.classList.toggle('open');
    }
};
